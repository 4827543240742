<template>
  <div class="menu">
    <div class="menu-item" v-for="(item,index) in list" :key="index" @click="onClick(item)">
      <img :src="item.img" class="menu-img" />
      <div>{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: 'Menu',
  setup() {
    return {
      list: [
        { text: "VIP专区", img: require('@/assets/img/shop/menu1.png') },
        { text: "无损幸运购", img: require('@/assets/img/shop/menu2.png'), url: 'luckyBuy' },
        { text: "联盟区", img: require('@/assets/img/shop/menu3.png') },
        { text: "数字一卡通", img: require('@/assets/img/shop/menu4.png') },
        { text: "任务中心", img: require('@/assets/img/shop/menu5.png') },
        { text: "众筹团购 ", img: require('@/assets/img/shop/menu6.png') },
        { text: "商务课程", img: require('@/assets/img/shop/menu7.png') },
        { text: "生活缴费", img: require('@/assets/img/shop/menu8.png') },
        { text: "特惠加油", img: require('@/assets/img/shop/menu9.png') },
        { text: "话费充值", img: require('@/assets/img/shop/menu10.png'), url: 'PhoneBill' }
      ]
    }
  },
  methods: {
    onClick(item) {
      if(item.url) {
        if(item.replace) {
          this.$router.replace(item.url)
        } else {
          this.$router.push(item.url)
        }
      } else {
        Toast('暂未开放');
      }
    }
  }
}
</script>

<style scoped>
.menu {
  display: flex;
  flex-wrap: wrap;
}

.menu-item {
  margin-top: 10px;
  width: 20%;
  text-align: center;
  font-size: 12px;
}

.menu-img {
  width: 42px;
  height: 42px;
}
</style>