<template>
  <div class="shop">
    <div class="container q-pb-md">
      <div class="header">
        <div class="header-top">
          <div class="row items-center">
            <img src="@/assets/img/shop/address.png" width="15" />
            <div>北京</div>
          </div>
          <div class="input-wrap">
            <q-input rounded borderless v-model="search" dense placeholder="请输入搜索关键词" @keyup.enter="onSearch">
              <template v-slot:prepend>
                <q-icon name="search" size="xs" />
              </template>
            </q-input>
          </div>
          <img src="@/assets/img/shop/qr.png" width="20" />
        </div>
        <van-tabs v-model="active" background="transparent" title-inactive-color="#fff" title-active-color="#fff" color="#fff"
          @click="tabChange">
          <van-tab :title="item.name" v-for="item in xllist" :key="item.id"></van-tab>
        </van-tabs>
      </div>

      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(254, 44, 45, 1)">
        <van-swipe-item>
          <img src="@/assets/img/shop/swiper-text.png" class="swiper-img" />
        </van-swipe-item>
      </van-swipe>

      <Menu />
    </div>

    <div class="goods-wrap">
      <van-list v-if="goodslist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
        finished-text="没有更多了" @load="onLoad">
        <GoodsList :list="goodslist" />
      </van-list>
      <van-empty description="暂无商品" v-if="goodslist.length == 0" />
    </div>

    <TabBar />
  </div>
</template>

<script>
import { ref } from 'vue';

import { Tab, Tabs, Swipe, SwipeItem, List, Empty } from 'vant';
import GoodsList from './components/GoodsList.vue'
import Menu from './components/Menu.vue'
import TabBar from '@/components/TabBar.vue'

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [List.name]: List,
    [Empty.name]: Empty,
    GoodsList,
    Menu,
    TabBar
  },
  setup() {
    return {
      search: ref(""),
      active: ref(0),
      xllist: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      goodslist: ref([])
    }
  },
  created() {
    this.getxllist()
  },
  methods: {
    // 商品加载下一页
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getGoods()
    },
    tabChange(e) {
      if(e != this.active) {
        this.active = e
        this.pageIndex = 1
        this.loading = false
        this.finished = false
        this.goodslist = []
        this.getGoods()
      }
    },
    onSearch() {
      this.$router.push('shopSearch?name=' + this.search)
    },
    getxllist() {
      let _this = this
      this.$request.post(
        "api/ShopGoods/Daxiaolei_List",
        {},
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            let xllist = []
            data.forEach(item => {
              item.dbShopClass2.forEach(item2 => {
                xllist.push(item2)
              })
            })
            _this.xllist = xllist
            _this.getGoods()
          }
        }
      )
    },
    getGoods() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/Search",
        {
          goodstype: 99,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          query: "",
          xlid: _this.xllist[_this.active].id
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.img = item.dbShopGoodsModel[0].img
              item.price = item.dbShopGoodsModel[0].price
            })
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            // console.log(data)
            _this.goodslist.push(...data)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.shop {
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.container {
  background-color: #fff;
}

.header {
  padding: 10px 10px 90px;
  border-radius: 0px 0px 36px 36px;
  background: linear-gradient(93.5deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.header-top {
  display: flex;
  align-items: center;
  color: #fff;
}

.input-wrap {
  margin: 0 15px;
  flex: 1;
  padding: 0 10px;
  border-radius: 99px;
  background-color: #fff;
}

.input-wrap :deep(.q-field--dense .q-field__control) {
  height: 35px;
}

.input-wrap :deep(.q-field--dense .q-field__marginal) {
  height: 35px;
}

.my-swipe {
  margin: -80px 10px 0;
}

.swiper-img {
  width: 100%;
  border-radius: 15px;
}

.goods-wrap {
  padding: 15px 0 0;
  background: rgba(242, 242, 242, 1);
}
</style>